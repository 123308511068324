<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()">

    <template v-slot:activator="{ on: dialog }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            x-small
            v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Change User</span>
      </v-tooltip>
    </template>

    <v-form v-model="isValid" ref="form" @submit.prevent="save">

      <v-card>

        <v-card-title class="headline">
          Change User

          <v-spacer />

          <v-switch
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="user.active"
            inset
            dark
            :label="user.active ? 'Active' : 'Suspended'" />
        </v-card-title>

        <v-divider />

        <v-card-text>
            <v-row>

              <v-col cols="12">
                <v-switch
                  v-if="$vuetify.breakpoint.xsOnly"
                  v-model="user.active"
                  inset
                  :label="user.active ? 'Active' : 'Suspended'" />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="user.account_name"
                  :rules="[rules.required, rules.min(2)]"
                  :background-color="isFieldDirty('account_name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Username"
                  outlined
                  clearable
                  v-model="user.username"
                  :rules="[rules.min(4)]"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Reset Password"
                  outlined
                  password
                  clearable
                  :counter="(user.password || '').length > 0"
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.passwordOrEmpty(8)]"
                  />
              </v-col>

              <v-col cols="1" class="px-4 py-0">
                <v-switch
                  label="CardNet"
                  inset
                  v-model="user.isCardNetUser" />
              </v-col>

              <v-col cols="12" class="px-4 pl-6">
                <v-expand-transition>

                  <div v-show="user.isCardNetUser">
                    <div class="d-flex">
                      <v-switch
                        class="flex-shrink-1"
                        inset
                        v-model="user.allChannels"
                        label="All Channels" />
                    </div>

                    <v-expand-transition>
                      <v-autocomplete
                        v-show="!user.allChannels"
                        label="Channels"
                        v-model="user.channels"
                        multiple
                        chips
                        clearable
                        deletable-chips
                        outlined
                        :placeholder="user.allChannels ? 'All Channels' : 'Select Channels'"
                        :items="availableChannels" />
                    </v-expand-transition>

                    <div class="d-flex">
                      <v-switch
                        inset
                        class="flex-shrink-1"
                        v-model="user.allChannelNetworks"
                        label="All Channel Networks" />
                    </div>

                    <v-expand-transition>
                      <!--:disabled="user.allChannelNetworks || !canSelectNetworks"-->
                      <!--:readonly="user.allChannelNetworks || !canSelectNetworks"-->
                      <v-autocomplete
                        v-show="!user.allChannelNetworks && canSelectNetworks"
                        v-model="user.networks"
                        label="Channel Networks"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        outlined
                        :placeholder="user.allChannelNetworks ? 'All Channel Networks' : 'Select Networks'"
                        :items="availableNetworks" />
                    </v-expand-transition>
                  </div>

                </v-expand-transition>

              </v-col>

              <v-col cols="1" class="px-4">
                <v-switch
                  label="CashSpot"
                  inset
                  v-model="user.isCashSpotUser" />
              </v-col>

                  <!--<pre>{{ availableTerminals }}</pre>-->
              <v-col cols="12" class="px-4 pl-6">
                <v-expand-transition>

                  <v-autocomplete
                    v-show="user.isCashSpotUser"
                    v-model="user.cashSpotTerminal"
                    label="Terminal"
                    chips
                    clearable
                    deletable-chips
                    outlined
                    placeholder="Select Terminal"
                    :items="availableTerminals" />

                </v-expand-transition>

              </v-col>

            </v-row>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

          <v-btn text width="72" @click="dialog = false;">Cancel</v-btn>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

          <v-btn color="primary" type="submit" :disabled="!canSave" :loading="saving">Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

export default {
  props: ['accountId'],

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      showPassword: false,

      user: {},

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
        passwordOrEmpty(len) { return v => {
          v = (v || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'networkById', 'channels', 'networks', 'terminals']),
    ...mapGetters('User', ['userByAccountId', 'isPortalUser', 'isClerkUser']),

    canSave() { return this.isDirty() && this.isValid },

    canSelectNetworks() {
      const user = this.user

      if (user.allChannels) {
        return true
      }
      else if ((user.channels || []).length > 0) {
        return true
      }

      return false
    },

    availableTerminals() {
      return this.terminals.map(terminal => {
        const network = this.networkById(terminal.network_id)
        const channel = this.channelById(network.channel_id)
        return { text: `${channel.name} / ${network.name} / ${terminal.name}`, value: terminal.id }
      })
    },

    availableChannels() {
      return this.channels
        .map(c => ({text: c.name, value: c.id}))
        .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    },

    availableNetworks() {
      const user = this.user
      const userChannelIds = user.channels ?? []

      if (user.allChannels) {
        return this.networks
          .map(n => ({text: `${this.channelById(n.channel_id).name} / ${n.name}`, value: n.id}))
          .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      }
      else {
        const channelsById = Object.fromEntries(userChannelIds.map(id => [id, true]))
        return this.networks
          .filter(n => channelsById[n.channel_id])
          .map(n => ({text: `${this.channelById(n.channel_id).name} / ${n.name}`, value: n.id}))
          .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
      }
    },

  },

  methods: {
    ...mapActions('User', ['saveUser']),

    modifiedUser(user) {

      if (!user) return

      // Convert user from server to browser format

      user = _.cloneDeep(user)

      const identity = Object.fromEntries(_.chunk(user.identities[0], 2))
      const actor = user.actors[0]

      let modifiedUser = {
        account_name: user.account_name,
        account_id: user.account_id,
        role_actor_id: actor.id,
        identity_id: identity.id,
        username: identity.username,
        active: user.roles.some(r => r.name == 'allow-auth' && r.enabled),
        isCardNetUser: this.isPortalUser(user.account_id),
        isCashSpotUser: this.isClerkUser(user.account_id),
        allChannels: user.roles.some(r => r.name == 'channels' && r.referenced_id == '*'),
      }

      modifiedUser.allChannelNetworks =
        //modifiedUser.allChannels
        //&&
        user.roles.some(r => r.name == 'networks' && r.referenced_id == '*')

      modifiedUser.channels = user.roles
        .filter(r => r.name == 'channels' && r.referenced_id.indexOf('chan') == 0)
        .map(r => r.referenced_id)

      modifiedUser.networks = user.roles
        .filter(r => r.name == 'networks' && r.referenced_id.indexOf('netw') == 0)
        .map(r => r.referenced_id)

      modifiedUser.cashSpotTerminal = user.roles
        .filter(r => r.name == 'clerk')
        .map(r => Object.fromEntries([r.values]).terminal_id)
        .first()

      //console.log({modifiedUser})

      return modifiedUser
    },

    initData() {
      this.user = this.modifiedUser(this.userByAccountId(this.accountId)) || {}

      this.saving = false
      this.error   = undefined
    },

    isFieldDirty(name) {
      const accountId = this.accountId

      let originalUser = this.userByAccountId(accountId)
      if (!originalUser) {
        console.error('Could not find original user for comparison', {accountId, name});
      }

      let original = originalUser?.[name]?.toString().trim() ?? ''
      let modified = this.user[name]?.toString().trim() ?? ''

      return original != modified
    },

    isDirty() {
      const diffed = diff(this.user, this.modifiedUser(this.userByAccountId(this.accountId)))
      //console.log({user: this.user, modified: this.modifiedUser(this.userByAccountId(this.accountId)), diff: d})
      const isDirty = Object.keys(diffed || {}).length > 0

      return isDirty

      //const keysMonitored = ['isCardNetUser', 'isCashSpotUser', 'active', 'username', 'password', 'name'].sort()
      //let isDirty = keysMonitored.some(key => this.isFieldDirty(key))

      //if (!user.isCardNetUser) delete user.channels && delete user.networks
      //if (!user.channels?.length) delete user.channels
      //if (!user.networks?.length) delete user.networks
      //if (user.allChannels) delete user.channels
      //if (user.allChannelNetworks) delete user.networks

      //return isDirty
    },

    save() {
      if (!this.canSave) return

      this.user.disabled = !this.user.active

      this.saving = true

      this.saveUser(this.user)

      .then(() => {
        this.saving = false
        this.dialog = false
      })

      .catch(error => {
        this.error = error?.response?.data ?? error?.message
        this.saving = false
      })
    }
  },

  watch: {
    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    },

    //user: {
    //  handler(newValue, oldValue) {
    //    debugger
    //    if (newValue.active != oldValue.active) {
    //      this.user.inactive = !this.user.active
    //    }
    //    else {
    //      this.user.active = !this.user.inactive
    //    }
    //  },
    //  deep: true,
    //},
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }

  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }

  /*
    Fix for autocomplete placeholder only showing on focus
    https://github.com/vuetifyjs/vuetify/issues/11553
  */
  .v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }

  /*
  .v-input--is-label-active label {
    color: white !important;
    font-weight: bold;
  }
  */
}
</style>

