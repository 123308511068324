<template>

  <v-container
    fluid
    class="ma-0 pa-0"
    >

    <!--t: {{ isViewedFromTerminalContext }}-->
    <!--<br />-->
    <!--u: {{ isViewedFromUserContext }}-->

    <Confirm ref="confirm" />

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'user'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto; overflow-x: hidden;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

         <!--<v-list-item class="pt-0 px-2">-->

          <v-list-item class="overline pt-0 mt-0 pl-1">
            User
            <v-spacer />

            <div class="pl-2">
              <ChangeUserDialog :accountId="user.account_id" />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    :loading="deleting"
                    @click="deleteUser">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </div>
          </v-list-item>

          <v-card
            outlined
            class="pa-0 ma-0 mt-2 flex-grow-1"
            style="">

            <v-card-title class="py-2">
              {{ user.account_name }}
              <v-spacer />
              <v-icon :style="{visibility: isUserActive(user.account_id) ? 'hidden' : 'visible'}" color="red">mdi-minus-circle-outline</v-icon>
            </v-card-title>

            <v-card-text class="pb-3">

              <div v-if="username">
                {{ username }} / *********
              </div>

              <div class="mt-4"></div>

              <div
                v-if="isPortalUser(user.account_id)"
                class="mt-4">

                <div class="overline">
                  CardNet
                </div>

                <div v-if="user.roles.some(r => r.name == 'channels' && r.referenced_id == '*')">
                  <li>All Channels</li>
                </div>
                <div v-else-if="user.roles.some(r => r.name == 'channels')">
                  <li
                    v-for="role in user.roles.filter(r => r.name == 'channels')"
                    :key="role.id">
                    {{ (channelById(role.referenced_id) || {}).name || role.referenced_id }}
                  </li>
                </div>

                <div v-if="user.roles.some(r => r.name == 'networks' && r.referenced_id == '*')">
                  <li>
                  All Channel Networks
                  </li>
                </div>

                <div v-else-if="user.roles.some(r => r.name == 'networks')">
                  <li
                    v-for="role in user.roles.filter(r => r.name == 'networks')"
                    :key="role.id">

                    <template v-if="role.referenced_id.indexOf('chan') == 0">
                      All Networks for Channel {{ (channelById(role.referenced_id) || {}).name || role.referenced_id }}
                    </template>
                    <template v-else>
                      Network {{ (networkById(role.referenced_id) || {}).name || role.referenced_id }}
                    </template>
                  </li>
                </div>
              </div>

              <div
                v-if="isClerkUser(user.account_id)"
                class="mt-4">

                <div class="overline">
                  CashSpot
                </div>

                <div>
                  <li
                    v-for="role in user.roles.filter(r => r.name == 'clerk')"
                    :key="role.id">
                    Terminal {{ (terminalById(Object.fromEntries([role.values]).terminal_id) || {}).name }}
                  </li>
                </div>
              </div>

              <div
                v-if="isViewedInTerminalContext"
                class="mt-4 d-flex justify-end">
                <v-btn
                  link
                  :to="{name: 'user', params: { account_id: user.account_id }}">
                  View in Users
                </v-btn>
              </div>

            </v-card-text>

          </v-card>

          <v-list
            v-if="isViewedInUserContext && terminals.length > 0"
            v-model="terminalSelectedIndex">

            <v-list-item class="overline pt-0 mt-0 pl-1">
              Assigned Terminals
              <v-spacer />

            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name, Email, Address, Fees"
                v-model="terminalFilter"
                @click:clear="clearTerminalFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="item in filteredTerminalList"
              :key="item.id"
              link
              :to="{name: 'assigned-terminal', params: { terminal_id: item.id }}">

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.terminals_count">
                  Authorized Clerks {{ item.terminals_count }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  <em>No Authorized Clerks</em>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon class="pr-2">
                <v-icon :style="{visibility: item.active ? 'hidden' : 'visible'}" color="red">mdi-minus-circle-outline</v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-1 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import ChangeUserDialog from '@/components/ChangeUserDialog.vue'
import Confirm from '@/components/Confirm.vue'

export default {
  data() {
    return {
      deleting: false,

      terminalFilter: '',
      terminalSelectedIndex: undefined,
      terminalSelected: 0,
      filteredTerminalList: [],


      user: {},
      terminals: [],
    }
  },

  metaInfo() {
    return {
      title: 'Networks',
    }
  },

  computed: {
    ...mapGetters('User', ['userByAccountId', 'isUserActive', 'terminalsByAccountId', 'isClerkUser', 'isPortalUser', 'identitiesByAccountId', 'users']),
    ...mapGetters('Channel', ['channelById', 'networkById', 'terminalById', 'networks']),

    isViewedInTerminalContext() { return !!this.$route.params.network_id },
    isViewedInUserContext() { return !this.isViewedInTerminalContext },

    username() {
      return this.identitiesByAccountId(this.user.account_id)?.map(i => i.username).join(',')
    },
  },

  components: {
    ChangeUserDialog,
    Confirm,
  },

  methods: {
    ...mapActions('Channel', [ 'getTerminalList']),
    ...mapActions('User', [ 'removeUser' ]),

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearTerminalFilter() {
      this.terminalFilter = ''
    },

    filterTerminalList() {
      const filter = this.terminalFilter?.toLowerCase() ?? ''
      const terminals = this.terminals

      const filteredTerminalList = filter.length == 0
        ? terminals
        : terminals.filter(terminal =>
            terminal.name?.toLowerCase().includes(filter) ||
            terminal.address?.toLowerCase().includes(filter) ||
            terminal.city?.toLowerCase().includes(filter) ||
            terminal.zip?.toLowerCase().includes(filter) ||
            terminal.country?.toLowerCase().includes(filter) ||
            terminal.email?.toLowerCase().includes(filter) ||
            terminal.phone?.toLowerCase().includes(filter) ||
            terminal.cashback_fee?.toString().toLowerCase().includes(filter) ||
            terminal.load_fee?.toString().toLowerCase().includes(filter)
          )

      this.filteredTerminalList = [...filteredTerminalList]
    },

    deleteUser() {

      this.$refs.confirm.open(
        'Delete User',
        'Deletion is permanent. Are you sure?',
      )

      .then(confirmed => {
        if (!confirmed) throw 0;

        this.deleting = true

        return this.removeUser(this.user.account_id)
      })

      .then(() => {
        return this.$router.push({name: 'users'})
      })

      .catch(error => {
        if (!error) return

        this.error = error?.response?.data ?? error?.message
      })

      .finally(() => {
        this.deleting = false
      })
    },
  },

  mounted() {
    this.getTerminalList()
  },

  watch: {

    '$route.params.account_id': {
      handler(newValue) {
        this.user = this.userByAccountId(newValue)

        this.terminals = this.isClerkUser(newValue)
          ? this.terminalsByAccountId(this.user.account_id)?.map(terminalId => this.terminalById(terminalId)) ?? []
          : []
      },
      immediate: true,
    },

    users() {
      const user = this.userByAccountId(this.user.account_id)

      if (!user) return

      this.user = user

      this.terminals = this.isClerkUser(this.user.account_id)
        ? this.terminalsByAccountId(this.user.account_id)?.map(terminalId => this.terminalById(terminalId)) ?? []
        : []
    },

    terminals: {
      handler() {
        this.filterTerminalList()
      },
      immediate: true,
    },

    terminalFilter: {
      handler() { this.filterTerminalList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>


